<template>
  <section class="page-checkout-success">
    <template v-if="order">
      <div class="page-header ">
        <div class="container">
          <div class="page-header__inner">
            <div class="page-header__left">
              <h1>
                Заказ успешно создан!
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div class="cart cart-order">
        <div class="container">
          <div class="cart__inner">
            <cart-suborder
              v-for="suborder in order.suborders"
              :key="suborder.id+3"
              :suborder="suborder"
              :is-checkout="true"
            />
          </div>
        </div>
      </div>
      <order-details 
        :show-order-number="false" 
        :show-order-text="true" 
        :order="order" 
        class="order-data--gray" 
      />
    </template>
  </section>
</template>

<script>
import OrderDetails from '@/components/orders/OrderDetails.vue'
import CartSuborder from '@/components/cart/CartSuborder.vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { SET_NEW_ORDER, SET_CART } from '@/store/modules/cart/mutationTypes'

export default {
  components: {
    OrderDetails,
    CartSuborder
  },
  setup () {
    const store = useStore()
    const router = useRouter()
    const order = store.getters.getNewOrder
    if (order) {
      store.commit(SET_CART, null)
      store.commit(SET_NEW_ORDER, null)
    } else {
      router.push('/orders')
    }
    return {
      order
    }
  }
}
</script>
